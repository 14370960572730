<template>
  <calendesk-tabs v-show="getStatsLoaded" :items="getItems" :route="route" />
</template>

<script>
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import path from "@/router/path";
import CalendeskTabs from "@/components/CTabs/CalendeskTabs.vue";
import roleActions from "@/calendesk/mixins/roleActions";
import sharedTabsActions from "@/calendesk/mixins/sharedTabsActions";

export default {
  name: "ToolsWrapper",
  components: { CalendeskTabs },
  mixins: [sharedActions, roleActions, sharedTabsActions],
  data() {
    return {
      route: path.tools.main,
    };
  },
  computed: {
    getItems() {
      const items = [];

      if (this.loggedUserCanEditWebsites) {
        items.push({
          id: this.availableTabs.tools.websites,
          title: this.$trans("websites"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.tools.websites },
          },
          content: () =>
            import("@/views/dashboard/pages/Tools/Websites/WebsiteList"),
        });
      }

      if (this.loggedUserCanEditMobileApp) {
        items.push({
          id: this.availableTabs.tools.app,
          title: this.$trans("application"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.tools.app },
          },
          content: () =>
            import("@/views/dashboard/pages/Tools/Mobile/MobileList"),
        });
      }

      if (this.loggedUserCanUseImages) {
        items.push({
          id: this.availableTabs.tools.assets,
          title: this.$trans("assets"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.tools.assets },
          },
          content: () =>
            import("@/views/dashboard/pages/Tools/Assets/ImageAssets"),
        });
      }

      if (this.loggedUserCanEditSettings) {
        items.push({
          id: this.availableTabs.tools.documents,
          title: this.$trans("regulations_and_terms_title"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.tools.documents },
          },
          content: () =>
            import("@/views/dashboard/pages/Tools/CompanyRegulations"),
        });
      }

      if (this.loggedUserCanDisplayLogs) {
        items.push({
          id: this.availableTabs.tools.logs,
          title: this.$trans("logs_title"),
          props: {},
          route: {
            name: this.route,
            params: { tab: this.availableTabs.tools.logs },
          },
          content: () => import("@/views/dashboard/pages/Tools/Logs/LogsList"),
        });
      }

      return items;
    },
  },
};
</script>
