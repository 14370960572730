import { mapActions, mapGetters } from "vuex";
import {
  employeePermissions,
  hasPermission,
} from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  mixins: [sharedActions],
  data() {
    return {
      employeePermissions,
    };
  },
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
    }),
    selectedUserIsRoot() {
      return (
        this.selectedUser &&
        this.selectedUser.roles &&
        this.selectedUser.roles.length > 0 &&
        this.selectedUser.roles[0].name === this.$helpers.roleSlugs.root
      );
    },
    selectedUserIsAdmin() {
      let isAdmin = false;

      if (
        this.selectedUser &&
        this.selectedUser.roles &&
        this.selectedUser.roles.length > 0
      ) {
        this.selectedUser.roles.forEach((role) => {
          if (
            role.name === this.$helpers.roleSlugs.root ||
            role.name === this.$helpers.roleSlugs.admin
          ) {
            isAdmin = true;
          }
        });
      }

      return isAdmin;
    },
    loggedUserEmployee() {
      if (this.loggedUser && this.loggedUser.employee) {
        return {
          ...this.loggedUser.employee,
          user: { ...this.loggedUser },
        };
      }

      return null;
    },
    loggedUserCanAddSimpleProducts() {
      return this.hasPermission(employeePermissions.ADD_STORE_PRODUCTS);
    },
    loggedUserCanAddUsers() {
      return this.hasPermission(employeePermissions.ADD_USERS);
    },
    loggedUserCanAddTags() {
      return this.hasPermission(employeePermissions.ADD_TAGS);
    },
    loggedUserCanAddGroups() {
      return this.hasPermission(employeePermissions.ADD_GROUPS);
    },
    loggedUserCanDeleteBookings() {
      return this.hasPermission(employeePermissions.DELETE_BOOKINGS);
    },
    loggedUserCanAddNotifications() {
      return this.hasPermission(employeePermissions.ADD_NOTIFICATIONS);
    },
    loggedUserCanSeeAllBookings() {
      return this.hasPermission(employeePermissions.SHOW_BOOKINGS);
    },
    loggedUserCanSeeAllEmployees() {
      return this.hasPermission(employeePermissions.SHOW_EMPLOYEES);
    },
    loggedUserCanDeleteUsers() {
      return this.hasPermission(employeePermissions.DELETE_USERS);
    },
    loggedUserCanUseImages() {
      return this.hasPermission(employeePermissions.SHOW_USER_IMAGES);
    },
    loggedUserCanDeleteStoreProductTransactions() {
      return this.hasPermission(
        employeePermissions.DELETE_STORE_PRODUCT_TRANSACTIONS,
      );
    },
    loggedUserCanEditMobileApp() {
      return (
        this.$config("customer_mobile_app_enabled") &&
        this.hasPermission(employeePermissions.MOBILE_APP_BUILDER_EDITOR)
      );
    },
    loggedUserCanEditWebsites() {
      return this.hasPermission(employeePermissions.WEBSITE_BUILDER_EDITOR);
    },
    loggedUserCanEditServices() {
      return this.hasPermission(employeePermissions.EDIT_SERVICES);
    },
    loggedUserCanEditTags() {
      return this.hasPermission(employeePermissions.EDIT_TAGS);
    },
    loggedUserCanEditGroups() {
      return this.hasPermission(employeePermissions.EDIT_GROUPS);
    },
    loggedUserCanEditResources() {
      return this.hasPermission(employeePermissions.EDIT_RESOURCES);
    },
    loggedUserCanEditEmployees() {
      return this.hasPermission(employeePermissions.EDIT_EMPLOYEES);
    },
    loggedUserCanEditSubscriptions() {
      return this.hasPermission(employeePermissions.EDIT_SUBSCRIPTIONS);
    },
    loggedUserCanEditStoreProducts() {
      return this.hasPermission(employeePermissions.EDIT_STORE_PRODUCTS);
    },
    loggedUserCanEditPackages() {
      return this.hasPermission(employeePermissions.EDIT_PACKAGES);
    },
    loggedUserCanShowNewsletter() {
      return this.hasPermission(employeePermissions.SHOW_NEWSLETTER);
    },
    loggedUserCanShowNotifications() {
      return this.hasPermission(employeePermissions.SHOW_NOTIFICATIONS);
    },
    loggedUserCanSeePayments() {
      return this.hasPermission(employeePermissions.PAYMENTS);
    },
    loggedUserCanEditSettings() {
      return this.hasPermission(employeePermissions.EDIT_SETTINGS);
    },
    loggedUserCanDisplayLogs() {
      return this.hasPermission(employeePermissions.SHOW_LOGS);
    },
    loggedUserCanSeeUserSubscriptionTransactions() {
      return (
        this.hasPermission(
          employeePermissions.SHOW_USER_SUBSCRIPTION_TRANSACTIONS,
        ) &&
        this.hasPermission(
          employeePermissions.EDIT_USER_SUBSCRIPTION_TRANSACTIONS,
        )
      );
    },
    loggedUserCanSeeProductsTransactions() {
      return (
        this.hasPermission(
          employeePermissions.SHOW_STORE_PRODUCT_TRANSACTIONS,
        ) &&
        this.hasPermission(
          employeePermissions.DELETE_STORE_PRODUCT_TRANSACTIONS,
        )
      );
    },
    loggedUserCanDeletePermissions() {
      return this.hasPermission(employeePermissions.DELETE_PERMISSIONS);
    },
  },
  methods: {
    ...mapActions({
      getRoles: "role/fetch",
    }),
    hasPermission,
    hasPermissions(permissions) {
      if (permissions) {
        const result = permissions.filter((permission) =>
          hasPermission(permission),
        );

        return result.length > 0;
      }

      return true;
    },
  },
};
