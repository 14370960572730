<template>
  <v-card :rounded="!flat" :elevation="flat ? 0 : 3" class="calendesk-tabs">
    <v-card-text class="pa-0 ma-0 mb-8">
      <slot name="top" />
      <v-tabs
        v-model="activeTab"
        :centered="centered"
        height="55"
        color="blue"
        show-arrows
        @change="onChange"
      >
        <v-tab
          v-for="(item, index) in items"
          :id="'tab_' + index"
          :key="item.id + '_tab'"
          :to="item.route"
          :disabled="disabled"
          :tab-value="item.id"
        >
          <div v-if="item.chip">
            {{ item.title }}
            <v-chip
              :color="item.chip_color"
              class="font-weight-bold white--text"
            >
              {{ item.chip }}
              <v-icon v-if="item.chip_icon" small>
                {{ item.chip_icon }}
              </v-icon>
            </v-chip>
          </div>
          <span v-else>
            {{ item.title }}
          </span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item
          v-for="item in items"
          :key="item.tab"
          :value="item.id"
          :transition="false"
          :reverse-transition="false"
        >
          <div v-if="item.content" class="mt-8">
            <component :is="item.content" v-bind="item.props" :ref="item.tab" />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CalendeskTabs",
  props: {
    route: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    centered: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters({
      getCTabState: "cache/getCTabsState",
    }),
  },
  created() {
    this.updateTabFromRoute();
  },
  methods: {
    ...mapActions({
      setCTabsState: "cache/setCTabsState",
    }),
    updateTabFromRoute() {
      const tab = this.$route.params.tab;
      if (tab) {
        const foundTab = this.items.find((item) => item.id === tab);
        this.activeTab = foundTab ? foundTab.id : 0;
      }

      this.onChange();
    },
    onChange() {
      this.setCTabsState({
        cacheId: this.route,
        data: this.activeTab,
      });
    },
  },
};
</script>
