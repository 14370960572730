export default {
  data() {
    return {
      availableTabs: {
        sales: {
          events: "events",
          subscriptions: "subscriptions",
          products: "products",
        },
        offer: {
          services: "services",
          subscriptions: "subscriptions",
          products: "products",
        },
        tools: {
          websites: "websites",
          app: "app",
          assets: "assets",
          documents: "documents",
          logs: "logs",
        },
        notifications: {
          settings: "settings",
          templates: "templates",
          newsletter: "newsletter",
          activity: "activity",
        },
        settings: {
          profile: "profile",
          company: "company",
          platform: "platform",
          tags: "tags",
          groups: "groups",
          resources: "resources",
          locations: "locations",
        },
        billing: {
          data: "data",
          invoices: "invoices",
          subscription: "subscription",
        },
      },
    };
  },
};
